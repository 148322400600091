<template>
  <div id="card-heading-container">

    <div class="circle " :style="style">
      <div class="text-container">
        <h1 class="circle-text">{{text?text:0}}</h1>
      </div>
    </div>
    <div class="round-edge ">
    <div class="text-container">

      <h1>{{title}}</h1>
    </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ShapedHeading",
  props: ["color", "text", "title"],
  computed: {
    style() {
      return 'background-color: ' + this.color;
    }
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sriracha&display=swap');
#card-heading-container{
  display: flex;

  background-color: transparent !important;

}
.circle{
  border-radius: 50%;
  width: 100px;
  z-index: 2;
  height: 100px;
  background-color: #E21415;
  box-shadow: 10px 10px 5px #aaaaaa;
}
.round-edge{
  margin-left: -50px;
  padding-left: 60px;
  box-shadow: 10px 10px 5px #aaaaaa;
  padding-right: 30px;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  background-color: white;
}
.circle-text{
  font-family: "Nunito", "Open Sans", sans-serif;
  color: white;
}
.text-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  font-family: 'Sriracha', cursive;
}
h1{
  font-size:1.5rem;
}

</style>
